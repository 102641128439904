import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import CardColumns from "react-bootstrap/CardColumns"

import LayoutEn from "../components/layout.en"
import Counters from "../components/counters"
import AdiLogo from "../components/adi-logo"
import MateszeLogo from "../components/matesze-logo"
import CzrsHrLogo from "../components/czrs-hr-logo"
import LinkBand from "../components/linkband"
import PostsList from "../components/postlist"
import Carousel from "react-bootstrap/Carousel"

const IndexPage = ({ location, data }) => {

  const title = "Home"
  const subtitle = "Learn all about assistant dogs!"

  return (
    <LayoutEn lang="en" showEu2020={true} location={location} title={title} description={subtitle}>

      <div style={{ position: "absolute", zIndex: "1", width: "100%" }}>
        <Container className="text-primary text-center mb-3 mt-5">
          <Row>
            <Col>
              <h1 className="text-white animated fadeInUp">AURA Assistant Dog Foundation</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="text-white animated fadeInUp">
                {subtitle}
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      <Carousel>
        <Carousel.Item>
          {/*<Link title="About Us" to="/en/rolunk/">*/}
            <Img style={{ position: "static" }} fixed={data.aboutUs.childImageSharp.fixed}/>
            <Carousel.Caption>
              <h2>About Us</h2>
              <p>Get some information about us!</p>
            </Carousel.Caption>
          {/*</Link>*/}
        </Carousel.Item>
        <Carousel.Item>
          {/*<Link title="Therapy dogs" to="/en/tudastar/terapias/">*/}
            <Img style={{ position: "static" }} fixed={data.therapy.childImageSharp.fixed}/>
            <Carousel.Caption>
              <h2>Therapy dogs</h2>
              <p>Trained dog for versatile therapies</p>
            </Carousel.Caption>
          {/*</Link>*/}
        </Carousel.Item>
        <Carousel.Item>
          {/*<Link title="Mobility Assistant Dogs" to="/en/tudastar/mozgasserultsegito/">*/}
            <Img style={{ position: "static" }} fixed={data.mobility.childImageSharp.fixed}/>
            <Carousel.Caption>
              <h2>Mobility Assistant Dogs</h2>
              <p>Assistant dog for every day</p>
            </Carousel.Caption>
          {/*</Link>*/}
        </Carousel.Item>
        <Carousel.Item>
          {/*<Link title="Seizure Alert Dog" to="/en/tudastar/rohamjelzo/">*/}
            <Img style={{ position: "static" }} fixed={data.diabetes.childImageSharp.fixed}/>
            <Carousel.Caption>
              <h2>Seizure Alert Dog</h2>
              <p>Epilepsy, diabetes or other chronic, seizure like state alerting</p>
            </Carousel.Caption>
          {/*</Link>*/}
        </Carousel.Item>
        <Carousel.Item>
          {/*<Link title="Personal Assistant Dog" to="/en/tudastar/szemelyisegito/">*/}
            <Img style={{ position: "static" }} fixed={data.personal.childImageSharp.fixed}/>
            <Carousel.Caption>
              <h2>Personal Assistant Dog</h2>
              <p>Partner tailored to Your needs</p>
            </Carousel.Caption>
          {/*</Link>*/}
        </Carousel.Item>
      </Carousel>

      {/*<LinkBand/>*/}

      {/*<Container fluid className="text-center">*/}
      {/*  <Link title="News" to="/en/hirek/">*/}
      {/*    <h2>News</h2>*/}
      {/*  </Link>*/}
      {/*  <PostsList postEdges={data.news.edges}/>*/}
      {/*</Container>*/}

      {/*<LinkBand/>*/}

      {/*<Container fluid={true} className="mt-5 text-center">*/}
      {/*  <CardColumns>*/}
      {/*    <Card border="primary">*/}
      {/*      <Card.Body>*/}
      {/*        <h3>Our mission</h3>*/}
      {/*        <Card.Text>*/}
      {/*          Az AURA Alapítvány kitűzött célja, hogy a kutyás terápia*/}
      {/*          módszerét és a segítő kutyák alkalmazásának előnyeit széles*/}
      {/*          körben megismertesse és elterjessze.*/}
      {/*        </Card.Text>*/}
      {/*        <Button href="/rolunk/">Ismerjen meg minket!</Button>*/}
      {/*      </Card.Body>*/}
      {/*    </Card>*/}
      {/*    <Card border="primary">*/}
      {/*      <Card.Body>*/}
      {/*        <h3>A csapat</h3>*/}
      {/*        <Card.Text>*/}
      {/*          Ismerje meg az AURA csapatát közelebbről is.*/}
      {/*        </Card.Text>*/}
      {/*        <Button href="/rolunk/tagok/" variant="primary">*/}
      {/*          A csapat*/}
      {/*        </Button>*/}
      {/*      </Card.Body>*/}
      {/*    </Card>*/}
      {/*    <Card border="primary">*/}
      {/*      <Card.Body>*/}
      {/*        <h3>In numbers</h3>*/}
      {/*        <Counters*/}
      {/*          members={49}*/}
      {/*          dogs={53}*/}
      {/*          yearsActive={18}*/}
      {/*          cities={16}*/}
      {/*          locations={39}*/}
      {/*          personsTherapy={785}*/}
      {/*        />*/}
      {/*      </Card.Body>*/}
      {/*    </Card>*/}
      {/*    <Card border="primary">*/}
      {/*      <Card.Body>*/}
      {/*        <h3>Átláthatóság</h3>*/}
      {/*        <Card.Text>*/}
      {/*          Az átláthatóság, az elszámoltathatóság és az integritás az AURA*/}
      {/*          működésének három legfontosabb alapelve. Tekintsen bele a*/}
      {/*          dokumentumainkba.*/}
      {/*        </Card.Text>*/}
      {/*        <Button href="/atlathatosag/" variant="primary">*/}
      {/*          Dokumentumok*/}
      {/*        </Button>*/}
      {/*      </Card.Body>*/}
      {/*    </Card>*/}
      {/*    <Card border="primary">*/}
      {/*      <Card.Body>*/}
      {/*        <h3>Miért a kutya?</h3>*/}
      {/*        <Card.Text>*/}
      {/*          Az ember és a kutya között megfigyelhető társas viselkedési párhuzamok magyarázzák a*/}
      {/*          kutya alkalmasságát mindazokra a feladatokra, amelyeket napjainkban ellát.*/}
      {/*        </Card.Text>*/}
      {/*        <Button href="/tudastar/miert-a-kutya/" variant="primary">*/}
      {/*          Tovább*/}
      {/*        </Button>*/}
      {/*      </Card.Body>*/}
      {/*    </Card>*/}
      {/*    <Card border="primary">*/}
      {/*      <Card.Body>*/}
      {/*        <h3>Terápiás kutya</h3>*/}
      {/*        <Card.Text>*/}
      {/*          A gyógypedagógiai, a szociális szolgáltatások területén pedagógiai, pszichológiai,*/}
      {/*          pszichiátriai, konduktív pedagógiai habilitációs, illetve rehabilitációs*/}
      {/*          folyamatban alkalmazott kutya.*/}
      {/*        </Card.Text>*/}
      {/*        <Button href="/tudastar/terapias/" variant="primary">*/}
      {/*          Tovább*/}
      {/*        </Button>*/}
      {/*      </Card.Body>*/}
      {/*    </Card>*/}
      {/*    <Card border="primary">*/}
      {/*      <Card.Body>*/}
      {/*        <h3>Személyi segítőkutya</h3>*/}
      {/*        <Card.Text>*/}
      {/*          A fogyatékos személyt önálló életvitelében segítő feladatokra kiképzett kutya.*/}
      {/*        </Card.Text>*/}
      {/*        <Button href="/tudastar/szemelyisegito/" variant="primary">*/}
      {/*          Tovább*/}
      {/*        </Button>*/}
      {/*      </Card.Body>*/}
      {/*    </Card>*/}
      {/*    <Card border="primary">*/}
      {/*      <Card.Body>*/}
      {/*        <h3>Mozgássérült segítő kutya</h3>*/}
      {/*        <Card.Text>*/}
      {/*          A mozgáskorlátozott személyt mindennapi tevékenységeinek ellátásában segítő*/}
      {/*          feladatokra kiképzett kutya.*/}
      {/*        </Card.Text>*/}
      {/*        <Button href="/tudastar/mozgasserultsegito/" variant="primary">*/}
      {/*          Tovább*/}
      {/*        </Button>*/}
      {/*      </Card.Body>*/}
      {/*    </Card>*/}
      {/*    <Card border="primary">*/}
      {/*      <Card.Body>*/}
      {/*        <h3>Rohamjelző kutya</h3>*/}
      {/*        <Card.Text>*/}
      {/*          Az epilepsziával élő személy vagy más krónikus, rohamszerű állapotoktól*/}
      {/*          veszélyeztetett személy segítésére kiképzett kutya.*/}
      {/*        </Card.Text>*/}
      {/*        <Button href="/tudastar/rohamjelzo/" variant="primary">*/}
      {/*          Tovább*/}
      {/*        </Button>*/}
      {/*      </Card.Body>*/}
      {/*    </Card>*/}
      {/*    <Card border="primary">*/}
      {/*      <Card.Body>*/}
      {/*        <h3>Kapcsolatok</h3>*/}
      {/*        <Container>*/}
      {/*          <Row>*/}
      {/*            <Col>*/}
      {/*              <a*/}
      {/*                href="https://assistancedogsinternational.org/"*/}
      {/*                aria-label="Assistance Dogs International"*/}
      {/*                target="_blank"*/}
      {/*                rel="noopener noreferrer"*/}
      {/*              >*/}
      {/*                <div style={{ margin: "auto", maxWidth: "276px" }}>*/}
      {/*                  <AdiLogo/>*/}
      {/*                </div>*/}
      {/*              </a>*/}
      {/*              <p>*/}
      {/*                Az AURA felvétele az Assistance Dogs International Európai*/}
      {/*                szervezetébe folyamatban van.*/}
      {/*              </p>*/}
      {/*            </Col>*/}
      {/*          </Row>*/}
      {/*          <Row>*/}
      {/*            <Col>*/}
      {/*              <a*/}
      {/*                href="http://czrs.hr/"*/}
      {/*                aria-label="Centar Za Rehabilitaciju Silver"*/}
      {/*                target="_blank"*/}
      {/*                rel="noopener noreferrer"*/}
      {/*              >*/}
      {/*                <div style={{ margin: "auto", maxWidth: "313px" }}>*/}
      {/*                  <CzrsHrLogo/>*/}
      {/*                </div>*/}
      {/*              </a>*/}
      {/*              <p>*/}
      {/*                Az AURA partnere a horvátországi Centar za Rehabilitaciju*/}
      {/*                Silver központnak.*/}
      {/*              </p>*/}
      {/*            </Col>*/}
      {/*          </Row>*/}
      {/*          <Row>*/}
      {/*            <Col>*/}
      {/*              <a*/}
      {/*                href="http://matesze.hu/"*/}
      {/*                aria-label="Magyar Terápiás és Segítőkutyák Szövetsége"*/}
      {/*                target="_blank"*/}
      {/*                rel="noopener noreferrer"*/}
      {/*              >*/}
      {/*                <div style={{ margin: "auto", maxWidth: "90px" }}>*/}
      {/*                  <MateszeLogo/>*/}
      {/*                </div>*/}
      {/*              </a>*/}
      {/*              <p>Az AURA tagja a MATESZ Egyesületnek.</p>*/}
      {/*            </Col>*/}
      {/*          </Row>*/}
      {/*        </Container>*/}
      {/*      </Card.Body>*/}
      {/*    </Card>*/}
      {/*  </CardColumns>*/}
      {/*</Container>*/}
    </LayoutEn>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    news: allMarkdownRemark(
      limit: 3
      filter: { fields: { category: { eq: "hirek" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            category
          }
          excerpt
          timeToRead
          frontmatter {
            title
            description
            date
          }
        }
      }
    }
    aboutUs: file(relativePath: { eq: "photo/Szuper.jpg" }) {
      childImageSharp {
        fixed(quality: 90, height: 600) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    therapy: file(relativePath: { eq: "photo/Csoportkép.jpg" }) {
      childImageSharp {
        fixed(quality: 90, height: 600) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    mobility: file(relativePath: { eq: "photo/Szabadban.jpg" }) {
      childImageSharp {
        fixed(quality: 90, height: 600) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    diabetes: file(relativePath: { eq: "photo/Zsömi index.jpg" }) {
      childImageSharp {
        fixed(quality: 90, height: 600) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    personal: file(relativePath: { eq: "photo/ozzy-oli-tin.jpg" }) {
      childImageSharp {
        fixed(quality: 90, height: 600) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
